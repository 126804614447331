import React, { useState } from 'react';
import axios from 'axios';

function App() {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('http://38.242.244.24/api/upload/', formData, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'output.xml');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert("An error occurred while uploading the file.");
            console.error(error);
        }
    };

    return (
        <div style={{ padding: "20px", textAlign: "center" }}>
            <h1 style={{ marginBottom: "25px" }}>Excel to XML Converter</h1>
            <div style={{ paddingTop: "25px", textAlign:'center' }}>
                <input type="file" onChange={handleFileChange}/>
                <button onClick={handleUpload}>Upload and Convert</button>
            </div>
        </div>
    );
}

export default App;
